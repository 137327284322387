import { inject } from '@nuxtjs/composition-api'
import { UserRole } from '../enums'
import { Permissions } from '../helpers/auth'

export function usePermissions () {
  const permissions = inject<Permissions>('permissions')
  if (!permissions) {
    throw new Error('Permissions not provided')
  }
  return permissions
}

export function useRole () {
  const role = inject<UserRole>('userRole')
  if (!role) {
    throw new Error('Role not provided')
  }
  return role
}
