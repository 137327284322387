import { provide, inject, watch } from '@nuxtjs/composition-api'
import { UseWebSocketReturn, useWebSocket } from '@vueuse/core'
import { WEBSOCKET } from '~/config/api'
import Invoice from '~/models/invoice'
import PlatformMessage, { ReadStatus } from '~/models/message'
import Note from '~/models/note'
import Organization from '~/models/organization'
import Shipment from '~/models/shipment'

export const websocketSymbol = Symbol('WEBSOCKET')

export enum WebSocketEventType {
  PlatformMessage = 'PlatformMessage',
  PlatformMessageRead = 'PlatformMessage:viewed',
  ReadStatus = 'ReadStatus',
  Shipment = 'Shipment',
  Invoice = 'Invoice',
  Organization = 'Organization',
  Note = 'Note'
}

export interface WebsocketEvent {
  id?: string,
  event: WebSocketEventType,
  data: any
}

export function provideWebsocket () {
  const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:'
  const wsUrl = `${protocol}//${window.location.host}/api${WEBSOCKET}`
  const ws = useWebSocket(wsUrl, {
    autoReconnect: true,
    onDisconnected (_ws, event) {
      console.log('Websocket disconnected:', event.code, event.reason)
    }
  })

  watch(ws.data, async (data) => {
    let jsonMessage: WebsocketEvent | undefined
    try {
      jsonMessage = JSON.parse(data)
    } catch {}

    if (typeof jsonMessage !== 'undefined') {
      switch (jsonMessage.event) {
        case WebSocketEventType.PlatformMessage:
          try {
            await PlatformMessage.insertOrUpdate({ data: jsonMessage.data })
          } catch (error) {}
          break
        case WebSocketEventType.ReadStatus:
          try {
            await ReadStatus.insertOrUpdate({ data: jsonMessage.data })
          } catch (error) {}
          break
        case WebSocketEventType.Shipment:
          try {
            await Shipment.insertOrUpdate({ data: jsonMessage.data })
          } catch (error) {}
          break
        case WebSocketEventType.Invoice:
          try {
            await Invoice.insertOrUpdate({ data: jsonMessage.data })
          } catch (error) {}
          break
        case WebSocketEventType.Organization:
          try {
            await Organization.insertOrUpdate({ data: jsonMessage.data })
          } catch (error) {}
          break
        case WebSocketEventType.Note:
          try {
            await Note.insertOrUpdate({ data: jsonMessage.data })
          } catch (error) {}
          break
      }
    }
  })
  provide(websocketSymbol, ws)
}

export function useConnectedWebsocket () {
  const ws = inject<UseWebSocketReturn<any>>(websocketSymbol)
  if (!ws) {
    throw new Error('useWebsocket() called without provider.')
  }
  return ws
}
