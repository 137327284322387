

































import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import { mdiLocationEnter, mdiLocationExit } from '@mdi/js'
import ReviewsListItemButtons from './ReviewsListItemButtons'
import { StatusFields } from '~/components/Containers/ReviewInteractor'
import SummaryBar from '~/components/Common/SummaryBar'
import ReviewForm from '~/components/Reviews/ReviewsDialog/ReviewForm'
import Review, { ReviewStatus } from '~/models/review'
import { useDeepWatch, useDeepSync } from '~/composables/hooks/propsync'

export default defineComponent({
  name: 'ReviewItem',
  components: {
    SummaryBar,
    ReviewForm,
    ReviewsListItemButtons
  },
  props: {
    review: {
      type: Object as PropType<Review>,
      default: () => new Review()
    },
    loadingStatus: {
      type: Object as PropType<StatusFields>,
      default: (): StatusFields => ({
        retract: false,
        save: false,
        postpone: false,
        refuse: false
      })
    },
    processed: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const propWatch = useDeepWatch(props)
    const deepSync = useDeepSync(props, emit)
    const shipment = computed(() => props.review.shipment)
    const currentPage = ref(1)
    const localProcessed = deepSync('processed')

    const localReview = propWatch('review')

    const formEmpty = computed(() =>
      currentPage.value === 1
        ? !localReview.value.score_overall
        : !localReview.value.score_communication && !localReview.value.score_customs && !localReview.value.score_delivery && !localReview.value.comments
    )

    watch(
      () => localProcessed.value,
      (val) => {
        if (val) {
          if (currentPage.value === 1 && localReview.value.status === ReviewStatus.STATUS_SUBMITTED) {
            currentPage.value = currentPage.value + 1
          } else if (currentPage.value === 2 ||
            localReview.value.status === ReviewStatus.STATUS_POSTPONED ||
            localReview.value.status === ReviewStatus.STATUS_REFUSED
          ) {
            emit('remove', props.review.id)
          }
          localProcessed.value = false
        }
      }
    )

    const save = (status: ReviewStatus, event: string) => {
      localReview.value.status = status
      const data = new Review(localReview.value)
      emit(event, data)
    }

    return {
      localReview,
      currentPage,
      formEmpty,
      shipment,
      mdiLocationExit,
      mdiLocationEnter,
      ReviewStatus,
      save
    }
  }
})
