import { Md5 } from 'ts-md5'
import isEmail from 'validator/es/lib/isEmail'
import { debounce } from 'lodash-es'
import { Ref, isRef } from '@nuxtjs/composition-api'
import { ProtoDateTime } from './types'

export const getGravatar = (email: string, options: {size?: number, fallback?: string} = {}) =>
  `https://www.gravatar.com/avatar/${Md5.hashStr(email)}.jpg?s=${options.size ?? 80}&d=${options.fallback ?? 'mp'}`

export const identifyUserForHubspot = (email: string) => {
  const wdw = window as any // Eliminate ts inference for Window
  const _hsq = wdw._hsq = wdw._hsq || []
  if (isEmail(email)) {
    _hsq.push(['identify', { email }])
    _hsq.push(['trackPageView'])
  }
}

export const setHubspotChatConfig = (email: string, token: string) => {
  const wdw = window as any // Eliminate ts inference for Window
  if (wdw.HubSpotConversations) {
    wdw.hsConversationsSettings = {
      identificationEmail: email,
      identificationToken: token
    }
  }
}

export const loadHubspotChatBubble = () => {
  // Load with a delay to ensure the widget is loaded after the page is fully rendered
  setTimeout(() => {
    const wdw = window as any // Eliminate ts inference for Window
    if (wdw.HubSpotConversations) {
      wdw.HubSpotConversations.widget.load()
    }
  }, 2000)
}

export const debouncedUpdate = <Model extends {}, Field extends keyof Model>(model: Ref<Model>|Model, field: Field, delay = 250) => debounce((value: Model[Field]) => {
  if (isRef(model)) {
    model.value[field] = value
  } else {
    model[field] = value
  }
}, delay)

export const filterStatusOptions = (allOptions: Record<string, string>, allowedOptions: any[]): Record<string, string> => {
  const allowedStatusOptions = allowedOptions.map(item => item.toString())
  return Object.entries(allOptions).reduce((result, [key, value]) => {
    if (allowedStatusOptions.includes(key)) {
      result[key] = value
    }
    return result
  }, {} as Record<string, string>)
}

export const protoDateTimeToDate = (protoDate: ProtoDateTime): Date => {
  return new Date(Date.UTC(
    protoDate.year, protoDate.month - 1, protoDate.day, protoDate.hours ?? 0, protoDate.minutes ?? 0, protoDate.seconds ?? 0, Math.floor((protoDate.nanos ?? 0) / 1e6)
  ))
}

export const envIsProduction = () => window.location.hostname === 'app.cargoplot.com'
