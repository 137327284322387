import { Model } from '@vuex-orm/core'
import { ADMIN } from '~/config/api'

export default class Carrier extends Model {
  // This is the name used as module name of the Vuex Store.
  static entity = 'carriers'

  static fields () {
    return {
      name: this.string(''),
      scac: this.string('')
    }
  }

  name!: string
  scac!: string

  public static fetchScacList () {
    return this.api().get(ADMIN.CARRIERS)
  }
}

interface Location {
  date: string
  portName: string
  portCode: string
  terminal: string
}

export enum LegType {
  UNSPECIFIED = 'TYPE_UNSPECIFIED',
  VESSEL = 'TYPE_VESSEL',
  FEEDER_OR_BARGE = 'TYPE_FEEDER_OR_BARGE',
  TRUCK_OR_RAIL = 'TYPE_TRUCK_OR_RAIL',
}

interface Route {
  transitDays: number
  cutOff: string
  legs: [{
    type: LegType
    vesselName: string
    vesselCode: string
    voyageCode: string
    service: string
    departure: Location
    arrival: Location
  }]
}

export interface CarrierRoutes {
  scac: string
  name: string
  portOfLoading: string
  portOfDischarge: string
  retrievedAt: string
  routes?: Route[]
}
