import { ActionTree, MutationTree } from 'vuex'
import { make } from 'vuex-pathify'
import { DataOptions } from 'vuetify'
import { RootState } from '~/store'

export const defaultTableOptions: DataOptions = {
  page: 1,
  itemsPerPage: 25,
  sortBy: [],
  sortDesc: [],
  groupBy: [],
  groupDesc: [],
  multiSort: true,
  mustSort: false
}

export const state = () => ({
  inquiries: {
    admin: {
      search: ''
    },
    shipper: {
      search: ''
    }
  },
  shipments: {
    admin: {
      search: '',
      tableOptions: { ...defaultTableOptions }
    },
    shipper: {
      search: ''
    },
    forwarder: {
      search: '',
      tableOptions: { ...defaultTableOptions }
    },
    forwarderDocs: {
      search: ''
    },
    shipperOverview: {
      search: '',
      tableOptions: { ...defaultTableOptions }
    }
  },
  invoices: {
    admin: {
      search: '',
      tableOptions: { ...defaultTableOptions },
      filters: {
        status: null,
        collector: null
      }
    }
  },
  organizations: {
    admin: '',
    shipments: {
      admin: ''
    }
  },
  arrivals: {
    admin: {
      search: ''
    }
  },
  departures: {
    admin: {
      search: ''
    }
  }
})
export type SearchState = ReturnType<typeof state>
export const mutations: MutationTree<SearchState> = make.mutations(state)
export const actions: ActionTree<SearchState, RootState> = make.actions(state)
