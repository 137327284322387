/* eslint-disable camelcase */
import { Config } from '@vuex-orm/plugin-axios'
import { cloneDeep, omit, pick } from 'lodash-es'
import CargoplotModel, { Usage } from './cargoplot'
import Shipment from './shipment'
import { ShipmentStageModel } from './shipmentStage'
import { OrganizationType, UserRole } from '~/composables/enums'
import { ADMIN, ORGANIZATION } from '~/config/api'
import User from '~/models/user'

const endpoints: Record<UserRole, string> = {
  [UserRole.CLIENT]: ORGANIZATION,
  [UserRole.ADMIN]: ADMIN.ORGANIZATION,
  [UserRole.FORWARDER]: ''
}

export interface AggregateScore {
  total: number,
  count: number
}

export default class Organization extends CargoplotModel {
  // This is the name used as module name of the Vuex Store.
  static entity = 'organizations'

  static state () {
    return {
      fetchTime: '',
      cursor: ''
    }
  }

  // List of all fields (schema) of the post model. `this.attr` is used
  // for the generic field type. The argument is the default value.
  static fields () {
    return {
      id: this.number(0),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      deleted_at: this.attr(null),
      name: this.string(''),
      hubspot_company_id: this.attr(''),
      users: this.hasMany(User, 'organization_id'),
      shipments: this.belongsToMany(
        Shipment,
        ShipmentStageModel,
        'forwarder_id',
        'shipment_id'
      ),
      type: this.number(0),
      logo_uri: this.attr(''),
      direct_representation_form_uri: this.attr(''),
      users_count: this.number(0),
      shipments_count: this.number(0),
      affiliate_code: this.attr(''),
      article_23: this.boolean(null),
      address_line_1: this.attr(''),
      address_line_2: this.attr(''),
      postal_code: this.attr(''),
      city: this.attr(''),
      country: this.attr(''),
      vat: this.attr(''),
      eori: this.string(''),
      website: this.attr(''),
      contact_email: this.attr(''),
      phone: this.attr(''),
      billing_email: this.attr(''),
      aggregate_score: this.attr(null),
      email_reference_regex: this.attr(null),
      shipments_per_year: this.attr(null),
      notes: this.attr(null),
      payment_term_days: this.number(0),
      invoicing_moment: this.string(''),
      shipment_overview_columns: this.attr(null),
      discount_basis_points: this.number(0),
      account_manager_id: this.attr(null),
      service_manager_id: this.attr(null),
      account_manager: this.belongsTo(User, 'account_manager_id'),
      service_manager: this.belongsTo(User, 'service_manager_id'),
      credit_limit_cents: this.number(0),
      invoice_original_currency: this.boolean(false),
      invoice_notes: this.string('')
    }
  }

  id!: number
  created_at!: string
  updated_at!: string
  deleted_at!: string | null
  name!: string
  hubspot_company_id!: string
  users!: User[]
  shipments!: Shipment[]
  type!: OrganizationType
  logo_uri!: string
  direct_representation_form_uri!: string
  users_count!: number
  shipments_count!: number
  affiliate_code!: string
  article_23!: boolean
  address_line_1!: string
  address_line_2?: string
  postal_code!: string
  city!: string
  country!: string
  vat!: string
  eori!: string
  billing_email!: string
  website!: string
  contact_email!: string
  phone?: string
  aggregate_score?: AggregateScore
  email_reference_regex?: string
  shipments_per_year?: string
  notes?: string
  payment_term_days?: number
  invoicing_moment?: string
  shipment_overview_columns?: string[]
  discount_basis_points?: number
  account_manager_id!: number
  account_manager?: User
  service_manager_id!: number
  service_manager?: User
  credit_limit_cents!: number
  invoice_original_currency!: boolean
  invoice_notes!: string

  /**
   * Fetch all organizations (cargoplot employees)
   *
   * @static
   * @param {Config} [config]
   * @return {*}  {Promise<Response>}
   * @memberof Organization
   */
  public static fetchAll (config?: Config, cursorUsage: Usage = 'save', reqTimeUsage: Usage = 'saveAndSend') {
    const cursor = { field: 'cursor', usage: cursorUsage }
    const lastRequestTime = { field: 'fetchTime', usage: reqTimeUsage }

    // If only new data is fetched, don't send a limit and don't use the cursor
    if (['send', 'saveAndSend'].includes(reqTimeUsage) && this.getState().adminFetchTime) {
      delete config?.params?.limit
      cursor.usage = 'none'
    }

    return this.fetch(
      ADMIN.ORGANIZATION,
      config,
      lastRequestTime,
      cursor
    )
  }

  /**
   * Fetch all forwarders from the API
   *
   * @static
   * @return {*}
   * @memberof Organization
   */
  public static fetchForwarders () {
    return this.search({
      params: {
        type: 'TYPE_FORWARDER'
      }
    })
  }

  /**
   * Search for organizations
   *
   * @static
   * @param {Config} [config]
   * @return {*}
   * @memberof Organization
   */
  public static search (config?: Config) {
    return this.api().get(`${ADMIN.ORGANIZATION}`, config)
  }

  /**
   * Fetch a single organization by ID for admin users (organization details page)
   * @param id The ID of the organization
   * @param config The axios or vuex-orm config parameters
   * @returns A response object containing the data
   */
  public static fetchOne (id?:string|number, config?: Config) {
    return this.api().get(`${ADMIN.ORGANIZATION}/${id}`, config)
  }

  /**
   * Update the current organization instance (cargoplot employees)
   *
   * @param {Partial<Organization>} data
   * @param {Config} [config]
   * @param {boolean} [admin=false]
   * @return {*}  {Promise<Response>}
   * @memberof Organization
   */
  public async persist (data: Partial<Organization>, config?: Config, role = UserRole.CLIENT) {
    const originalRecord = this.$toJson()
    const sanitizedData = sanitize(data, role)
    try {
      // Already merge the changes in the local data set as if the remote operation succeeded
      // (i.e. optimistic UI)
      Organization.update({ where: this.id, sanitizedData })
      return await Organization.api().put(`${endpoints[role]}/${this.id}`, sanitizedData, config)
    } catch (e) {
      // On error, restore the original record.
      Organization.update({ where: this.id, data: originalRecord })
      throw e
    }
  }

  /**
   * Save a new organizations (cargoplot employees)
   *
   * @static
   * @param {Config} config
   * @returns {Promise<Response>}
   * @memberof Organization
   */
  public static createNew (data: Partial<Organization>, config?: Config) {
    return this.api().post(`${ADMIN.ORGANIZATION}`, sanitize(data, UserRole.ADMIN), config)
  }

  /**
   * Delete the current organization instance (cargoplot employees)
   *
   * @param {Number} data
   * @return {Promise<Response>}
   * @memberof Organization
   */
  public static deleteOne (id: number|string) {
    return this.api().delete(`${ADMIN.ORGANIZATION}/${id}`, {
      delete: id
    })
  }

  public get profile_completed () {
    const fields: (keyof this)[] = [
      'name', 'address_line_1', 'postal_code', 'city', 'country', 'vat', 'contact_email',
      'billing_email', 'vat'
    ]
    const fieldValues: string[] = Object.values(pick(this.$toJson(), fields))
    return fieldValues.every(value => typeof value === 'string' && !!value.trim())
  }
}

function sanitize (data: Partial<Organization>, role = UserRole.CLIENT) {
  let omitFromOrganization: (keyof Organization)[] = ['id', '$id', 'created_at', 'deleted_at', 'updated_at',
    'hubspot_company_id', 'affiliate_code', 'users', 'shipments', 'shipments_count', 'users_count',
    'aggregate_score', 'account_manager', 'service_manager']

  if (data.website && !data.website.startsWith('http')) {
    data.website = `https://${data.website}`
  }

  if (role === UserRole.CLIENT) {
    const fields: (keyof Organization)[] = [
      'logo_uri', 'direct_representation_form_uri', 'type', 'email_reference_regex', 'notes', 'payment_term_days',
      'invoicing_moment', 'shipment_overview_columns', 'discount_basis_points', 'account_manager_id', 'service_manager_id',
      'credit_limit_cents', 'invoice_original_currency', 'invoice_notes'
    ]
    omitFromOrganization = [
      ...omitFromOrganization, ...fields
    ]
    if (data.id) {
      omitFromOrganization.push('name')
    }
  }
  return omit(cloneDeep(data), omitFromOrganization)
}
