





















































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { mdiAxisArrow, mdiLadder, mdiPackageVariantClosed, mdiViewDashboardVariant, mdiWeightKilogram } from '@mdi/js'
import { CargoTypes } from '~/composables/enums'
import { useI18n } from '~/composables/hooks'
import Inquiry from '~/models/inquiry'
import Shipment from '~/models/shipment'
import Quote from '~/models/quote'

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Inquiry | Shipment | Partial<Quote>>,
      required: true
    },
    labelColor: {
      type: String,
      default: 'black'
    },
    showEditingValue: {
      type: Boolean,
      default: false
    },
    flexJustify: {
      type: String,
      default: 'justify-space-between'
    }
  },
  setup (props) {
    const { localeISO } = useI18n()

    const cargoTypeIcon = (type: CargoTypes|undefined) => ({
      [CargoTypes.UNSPECIFIED]: '',
      [CargoTypes.PACKAGES]: mdiPackageVariantClosed,
      [CargoTypes.CONTAINERS]: mdiViewDashboardVariant,
      [CargoTypes.PALLETS]: mdiLadder
    }[type ?? CargoTypes.UNSPECIFIED])

    const total = computed(() => {
      if (!props.item) {
        return 0
      }
      if (props.item.cargo_type === CargoTypes.CONTAINERS) {
        return props.item.containers?.reduce((total, ctr) => total + ctr.quantity, 0)
      } else {
        return props.item.cargo_components?.reduce((total, comp) => total + comp.quantity, 0)
      }
    })

    const tempTotalWeight = computed(() => {
      if (!props.item) {
        return 0
      }
      return props.item.cargo_components?.reduce((total, comp) => total + comp.quantity * comp.weight, 0)
        .toLocaleString(localeISO, {
          maximumFractionDigits: 2
        })
    })

    const tempTotalVolume = computed(() => {
      if (!props.item) {
        return 0
      }
      return props.item.cargo_components?.reduce((total, comp) => total + comp.quantity * (comp.depth / 100) * (comp.width / 100) * (comp.height / 100), 0)
        .toLocaleString(localeISO, {
          maximumFractionDigits: 2
        })
    })

    const checkIfQuote = (item: Inquiry | Shipment | Partial<Quote>): item is Quote => item instanceof Quote
    const isQuote = computed(() => checkIfQuote(props.item))

    return { total, cargoTypeIcon, isQuote, localeISO, mdiWeightKilogram, mdiAxisArrow, tempTotalWeight, tempTotalVolume }
  }
})
